import React, { useEffect, useState, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Grommet,
  Header,
  Nav,
  Table,
  Anchor,
  Layer,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableRow,
  Text,
  Main,
  Avatar,
  ThemeType,
} from "grommet";
import firebase from "firebase/app";
import "firebase/auth";
import { ProcessRuleResult } from "../../common/transaction-processing";

type ProcessedTransaction = Pick<
  ProcessRuleResult,
  "status" | "ruleId" | "timestamp" | "description" | "transactionId"
>;

const customTheme: ThemeType = {
  global: {
    font: {
      family: "Helvetica",
    },
  },
  table: {
    body: {
      align: "center",
      pad: { horizontal: "large", vertical: "xsmall" },
      border: "horizontal",
    },
    footer: {
      align: "start",
      border: undefined,
      pad: { horizontal: "large", vertical: "small" },
      verticalAlign: "bottom",
    },
    header: {
      align: "center",
      border: "bottom",
      fill: "horizontal",
      pad: { horizontal: "large", vertical: "xsmall" },
      verticalAlign: "bottom",
      background: {
        color: "neutral-3",
        opacity: 0.8,
      },
    },
  },
};

const Index = () => {
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null);
  const [, setError] = useState(false);
  const [user, setUser] = useState<firebase.User | null>(null);
  const signInElement = useRef<HTMLDivElement>(null);
  const [transactions, setTransactions] = useState<ProcessedTransaction[]>([]);
  const [, setLoginOverlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plaidLoaded, setPlaidLoaded] = useState(false);

  useEffect(() => {
    user?.getIdTokenResult()?.then(({ token }) => {
      fetch("/api/user", {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        method: "POST",
      })
        .then((resp) => {
          return resp.json();
        })
        .then(({ linkToken }) => {
          setError(false);
          setPlaidLinkToken(linkToken);
        })
        .catch((ex) => {
          console.log(ex);
          setError(true);
        });
    });
  }, [plaidLoaded, user]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";

    script.onload = () => {
      setPlaidLoaded(true);
    };

    document.body.appendChild(script);

    const firebaseConfig = {
      apiKey: process.env.GATSBY_FIREBASE_API_KEY,
      authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
      storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.GATSBY_FIREBASE_APP_ID,
    };

    const firebaseApp = firebase.initializeApp(firebaseConfig);
    firebaseApp.auth().onAuthStateChanged(function (user) {
      setUser(user);
    });
  }, []);

  const updateTransactions = useCallback((idToken: string) => {
    setLoading(true);
    fetch("/api/accounts/processed_transactions", {
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => res.json())
      .then((json) => {
        setTransactions(json?.transactions || []);
      })
      .catch((err) => {
        setTransactions([]);
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (user == null) {
      setTransactions([]);
    }
    user?.getIdTokenResult()?.then((idToken) => {
      updateTransactions(idToken.token);
    });
  }, [user]);

  const linkAccounts = useCallback(() => {
    user?.getIdTokenResult()?.then((idToken) => {
      const handler = window.Plaid.create({
        token: plaidLinkToken,
        onSuccess: (publicToken: string, metadata: Object) => {
          fetch("/api/accounts/link", {
            headers: {
              Authorization: "Bearer " + idToken.token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              publicToken,
              metadata,
            }),
            method: "POST",
          })
            .then((res) => res.json())
            .then(() => updateTransactions(idToken.token))
            .catch((err) => console.log(err));
        },
        onLoad: () => {},
        onExit: () => {},
        onEvent: () => {},
        receivedRedirectUri: null,
      });
      handler.open();
    });
  }, [plaidLinkToken, user]);

  const logInOrOut = useCallback(() => {
    if (user) {
      firebase.auth().signOut();
      return;
    }

    import("firebaseui").then((firebaseUiModule) => {
      if (!signInElement.current) {
        return;
      }

      const firebaseUi = new firebaseUiModule.auth.AuthUI(firebase.auth());
      firebaseUi.start(signInElement.current, {
        signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
        signInFlow: "popup",
        callbacks: {
          signInSuccessWithAuthResult: () => {
            return false;
          },
          uiShown: () => {
            setLoginOverlay(true);
          },
        },
      });
    });
  }, [user, signInElement]);

  return (
    <Grommet theme={customTheme}>
      <Helmet>
        <title>Manage Accounts</title>
        <link
          type="text/css"
          rel="stylesheet"
          href="https://www.gstatic.com/firebasejs/ui/4.7.1/firebase-ui-auth.css"
        />
      </Helmet>
      <Header background="light-4" pad="small">
        {user && (
          <Avatar background="neutral-2">{user.displayName?.[0] || "U"}</Avatar>
        )}
        <Nav direction="row">
          <Anchor
            disabled={!plaidLinkToken || !user}
            onClick={linkAccounts}
            label="Add Bank Account"
            margin="0 8px"
          />
          <Anchor
            color="primary"
            label={user ? "Logout" : "Login"}
            onClick={logInOrOut}
            margin="0 8px"
            size="small"
          />
        </Nav>
      </Header>
      <Layer modal={false}>
        <Box ref={signInElement} />
      </Layer>
      {loading && <Layer>Please Wait...</Layer>}
      <Main>
        <Table
          caption={`Transactions for ${user?.displayName}`}
          alignSelf="stretch"
        >
          <TableHeader>
            <TableRow>
              <TableCell scope="col">
                <Text>Date</Text>
              </TableCell>
              <TableCell scope="col">
                <Text>Rule</Text>
              </TableCell>
              <TableCell scope="col">
                <Text>Transaction Id</Text>
              </TableCell>
              <TableCell scope="col">
                <Text>Description</Text>
              </TableCell>
              <TableCell scope="col">
                <Text>Status</Text>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {transactions.map(
              (
                { timestamp, ruleId, transactionId, description, status },
                idx
              ) => (
                <TableRow key={`id${idx}`}>
                  <TableCell scope="row">
                    <Text>{timestamp}</Text>
                  </TableCell>
                  <TableCell scope="row">
                    <Text>{ruleId}</Text>
                  </TableCell>
                  <TableCell scope="row">
                    <Text>{transactionId}</Text>
                  </TableCell>
                  <TableCell scope="row">
                    <Text>{description}</Text>
                  </TableCell>
                  <TableCell scope="row">
                    <Text>{status}</Text>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
          <TableFooter>Pages</TableFooter>
        </Table>
      </Main>
    </Grommet>
  );
};

export default Index;
